import axios from 'axios';
import packageJson from '../../package.json';


export const getFlightData = async (code: string, email: string) => {
    
    let  url = window.location.href 
    if (url.indexOf('localhost:3006')>=0){
        url = window.location.origin.replace('localhost:3006', 'localhost/getmajoo') + window.location.pathname+"/"
    }else{
        if (packageJson.apiEndpoint){
            url = packageJson.apiEndpoint
        }else{
            url = "https://getmajoo.com/app/viagens/api/"
        }
        
        
        
    }
    
    const urlApi = url + "?code=" + code + "&email=" + email
    
    try {
        const response = await axios.get(urlApi)
        if (response.status === 200) {
            return response.data
        }
        
       
    } catch (e) {
       console.log(e)
    }

}