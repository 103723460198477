import React, { useState, useEffect }  from 'react';
import {Accommodation} from '../models/Accommodation';
import ErrorMessage from '../components/Error';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import PlaceIcon from '@mui/icons-material/Place';
import { Settings } from '../models/Settings';
import { ReactComponent as HotelSvg } from '../assets/icons/hotel.svg'
import { styled } from "@mui/material/styles";
import {Translate} from '../utils/translation'
import { Translation } from '../models/Translation';
import {formatDateToLanguageStandard,formatTimeToLanguageStandard} from '../utils/date'

const CardContentNoPadding = styled(CardContent)(`
  
  &:last-child {
    padding-bottom: .3em;
  }
`);

interface AccomodationProps{
    accommodations:Accommodation[]
    settings:Settings,
    dictionary: Translate
}

const AccommodationPage = (props:AccomodationProps) =>{

  const accommodations = props.accommodations
  const settings = props.settings
  const dictionary = props.dictionary
  const language = settings.language

  console.log(dictionary)

  const renderAccommodations = (flights:Accommodation[])=>{

        const accommodationList = flights.map((accommodation:Accommodation)=>{

            const checkinTime =  formatTimeToLanguageStandard(accommodation.checkin_time.padStart(5, '0'),language)
            const suffixCheckinTime = (language.toUpperCase()==='EN') ?  
                                        (<span className='suffix-time' style={{fontSize:'.7em',lineHeight:'2.1em'}}>{checkinTime.substring(5,8)}</span>):''
            const checkoutTime   =  formatTimeToLanguageStandard(accommodation.checkout_time.padStart(5, '0'),language)
            const suffixCheckoutTime = (language.toUpperCase()==='EN') ?  
                                        (<span className='suffix-time' style={{fontSize:'.7em',lineHeight:'2.1em'}}>{checkoutTime.substring(5,8)}</span>):''
        
        

            return <div key={accommodation.name} style={{marginBottom:'1em', marginTop: '1em'}}>
                    
                        <Card sx={{ width:'100%' ,padding:0}}>
                            <CardContentNoPadding>
                                <div className="flight-transferred-data">
                                    <div className="accommodation_column">
                                        <span style={{fontSize:10}}>CHECK-IN</span>
                                        <span style={{fontSize:'35px',color:'#1B1B1B',fontWeight:600,fontFamily:'Open Sans Condensed, sans-serif'}}>{formatDateToLanguageStandard(accommodation.checkin_date,language).substr(0,5)}</span>
                                        <span style={{color:'#1B1B1B',fontSize:20}}>{checkinTime.substring(0,5)} {suffixCheckinTime}</span>
                                        
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'1em'}}>
                                        <HotelSvg  fill={settings.color} stroke={settings.color}  color={settings.color} className='img_icon'/>
                                        <span style={{fontSize:14,textAlign:'center',color:"#1B1B1B",fontWeight:300,paddingTop:'.3em'}}>{accommodation.name.toUpperCase()}</span>
                                    </div>
                                    <div className="accommodation_column">
                                        <span style={{fontSize:10}}>CHECK-OUT</span>
                                        <span style={{fontSize:'35px',color:'#1B1B1B',fontWeight:600,fontFamily:'Open Sans Condensed, sans-serif'}} >{formatDateToLanguageStandard(accommodation.checkout_date,language).substr(0,5)}</span>
                                        <span style={{color:'#1B1B1B',fontSize:20}}>{checkoutTime.substring(0,5)} {suffixCheckoutTime}</span>
                                       
                                    </div>
                                
                                </div>
                                <Divider sx={{marginTop:'.3em'}}/> 
                                <div className="flight-transferred-data" style={{padding:0}}>
                                    <div style={{display: 'flex',flexDirection: 'row',alignItems: 'center',marginTop:'.6em',marginBottom:'.3em'}}>
                                        <PlaceIcon sx={{height:'18px',color:settings.color}}/><span style={{fontSize:10,color:'#1B1B1B',fontWeight:200}}>{accommodation.address}</span>
                                    </div>
                                   
                                </div>
                                
                            </CardContentNoPadding>
    
                        </Card>
                </div>
        })
        return accommodationList
  }
  if(accommodations.length>0){
   
    
    return(
        <div className="accommodation_data">
            <div style={{borderRadius:25,paddingLeft:'.5em',paddingRight:'.5em'}}>
                 {renderAccommodations(accommodations)}
            </div>
        </div>
    ) 
  }

  return (
        <div className="main">
            <div className="error-message-component">
                <ErrorMessage message={dictionary.getMessagesByCode('nenhum_voo_encontrado')} />
            </div>
        </div>
  );
}
export default AccommodationPage; 