
import { Translation } from '../models/Translation';

export interface Message{
    portuguese:string;
    english:string;
    spanish:string;
}
export class Translate  {

    private dictionary:Translation[]
    private language:string;
    constructor(dict:Translation[]=[],language:string='pt'){
        this.dictionary = dict;
        this.language = language;
    }
    getTranslation = (code:string)=>{
        const term = this.dictionary.find((term: Translation) => term.code === code);
        if(term){
            switch (this.language.toUpperCase()) {

                case 'EN':
                    return term.en
                case 'ES':
                    return term.es
                case 'PT':
                    return term.pt
                default:
                    return term.pt
            }
        }
        return code
    }
    getMessagesByCode = (code:string) : Message=>{
        const term = this.dictionary.find((term: Translation) => term.code === code);
        if(term){
            return {
                "portuguese":term.pt,
                "english":term.en,
                "spanish":term.es
            }
        }
        return {
            "portuguese":code,
            "english":code,
            "spanish":code
        }
    }
    getLocationAirportByLanguage = (airport:any) => {
       
        switch (this.language.toUpperCase()){

            case 'EN':
                return airport.local_EN || airport.local_en
            case 'ES':
                return airport.local_ES || airport.local_es;
            case 'PT':
                return airport.local
            default:
                return airport.local
        }   

    }
    

}