import React, { useState, useEffect }  from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Message} from '../utils/translation'


interface ErrorProps{
    message:Message;
    colorMessage?:string;
    code?:string;
}
const ErrorMessage = (props:ErrorProps) =>{

    const message = props.message
    const code = props.code
    const colorMessage = (props.colorMessage && props.colorMessage.length>0)? {color:props.colorMessage}:{color:'#737070'}
    const showCodeError = (code && code.length>0) ? <div className="error_msg_code" style={{...colorMessage}}>Erro: {code}</div> : null
    const showIconError = (code && code.length>0) ? (<div className="icon_error_msg">
                                                        <ErrorOutlineIcon  sx={{ fontSize:'3em',marginBottom:'.3em',marginTop:'.3em',color:colorMessage  }}  />
                                                   </div>) : null

    const errorStyle = (code && code.length>0) ? {color:'#2329D6',padding:0}: {color:'black'}
    return (
        <Card sx={{ minWidth: 275,margin:'0 2em',width:'90%' }}>
                <CardContent sx={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center'}}>
                    {showIconError}
                    <div className="error_msg" style={errorStyle}>
                        <span style={{fontSize:16,...colorMessage}}>{message.portuguese}</span><br/>
                        <span style={{fontSize:13,...colorMessage}}>{message.english}</span><br/>
                        <span style={{fontSize:13,...colorMessage}}>{message.spanish}</span>
                    </div>
                    {showCodeError}
                </CardContent>
                
            </Card>
    )

}
export default ErrorMessage;