export interface Message{
    code:string;
    message:string;
}

export class TranslateErrorMessage  {
    private language?:string;

    private messagesPT:Message[] = [
        {
            code:"E05",
            message:"Código ou e-mail não informados"
        },
        {
            code:"E06",
            message:"Falha ao conectar no servidor de dados"
        },
        {
            code:"E07",
            message:"Desculpe, algo deu errado. Por favor, tente novamente mais tarde"
        },
    ]
    private messagesEN: Message[] = [
        {
            code:"E05",
            message:"Code or email not provided"
        },
        {
            code:"E06",
            message:"Failed to connect to the data server"
        },
        {
            code:"E07",
            message:"Sorry, something went wrong. Please try again later"
        },
    ]
    private messagesES:Message[] = [
        {
            code:"E05",
            message:"Código o correo electrónico no proporcionado"
        },
        {
            code:"E06",
            message:"No se pudo conectar al servidor de datos"
        },
        {
            code:"E07",
            message:"Lo siento, algo salió mal. Por favor, inténtelo de nuevo más tarde"
        },
    ]

    constructor(language?:string){
        this.language = language
    }   
    getMessage(code:string){

        const defaultMessage = "Desculpe, algo deu errado. Por favor, tente novamente mais tarde"
        const defaultMessageEN = "Sorry, something went wrong. Please try again later"
        const defaultMessageES = "Lo siento, algo salió mal. Por favor, inténtelo de nuevo más tarde."

        switch(this.language?.toUpperCase()){
            case 'EN':
                return this.messagesEN.find((message:Message)=>message.code===code)?.message??defaultMessageEN
            case 'ES':
                return this.messagesES.find((message:Message)=>message.code===code)?.message??defaultMessageES
            case 'PT':
                return this.messagesPT.find((message:Message)=>message.code===code)?.message??defaultMessage
            default:
                return this.messagesPT.find((message:Message)=>message.code===code)?.message??defaultMessage

        }
        

    }
     getMessagesByCode(code:string){

        const defaultMessage = "Desculpe, algo deu errado"
        const defaultMessageEN = "Sorry, something went wrong"
        const defaultMessageES = "Lo siento, algo salió mal"

        return {
            portuguese:this.messagesPT.find((message:Message)=>message.code===code)?.message??defaultMessage,
            english:this.messagesEN.find((message:Message)=>message.code===code)?.message??defaultMessageEN,
            spanish:this.messagesES.find((message:Message)=>message.code===code)?.message??defaultMessageES
        }
        

    }

}