
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useSearchParams} from  'react-router-dom';
import { Settings } from '../models/Settings';

interface HeaderProps{
    user:string;
    settings:Settings
}
const Header = (props:HeaderProps) =>{

    const [queryParameters] = useSearchParams()
    const picture = queryParameters.get("picture");

    const client = props.settings.client;
    const destiny = props.settings.destiny

    const user = props.user
    const renderAvatar = ()=>{

        if(!picture){
            return (
                 <Avatar alt="Avatar" sx={{ bgcolor: "#D9D9D9" }}>
                    <PersonOutlineIcon />
                </Avatar>
            );
        }
       
        return (
             <Avatar alt="Avatar" src={picture} sx={{width:'3em',height:'3em'}}/>
        );
    }
    return (
        <div className="header-trip">
            <div className="header-account-data">
               {renderAvatar()}
               <div className="account-data">
                <span style={{color:props.settings.color,fontSize:'14px',paddingRight:'2em'}}>{user}</span>
                <span style={{fontSize:'12px',paddingLeft:'.1em',paddingRight:'2em'}}>{client} - {destiny}</span>
               </div>
            </div>
        </div>
    );
}

export default Header;