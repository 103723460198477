import * as moment from "moment";
export const formatDateToLanguageStandard = (date:string,language?:string,showTime=false)=>{
        if(!language){
            return date
        }
        if(language.toUpperCase()==="EN"){
            if(showTime){
                 return moment(date,'DD/MM/YYYY HH:mm:ss').format('MM/DD/YYYY hh:mm:ss A')
            }else{
                return moment(date,'DD/MM/YYYY').format('MM/DD/YYYY')
            }
            
        }
        return date
}
export const formatTimeToLanguageStandard = (time:string,language:string)=>{
        if(language.toUpperCase()==="EN"){
            
            return moment(time,'HH:mm').format('hh:mm A')
        
        }
        return time
}