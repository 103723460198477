import React, { useState, useEffect }  from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Flight} from '../models/Flight'
import { Airport } from '../models/Airport';
import ErrorMessage from '../components/Error'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Divider from '@mui/material/Divider';
import { ReactComponent  as PlaneSVG } from '../assets/icons/plane_trip.svg'
import { Settings } from '../models/Settings';
import { styled } from "@mui/material/styles";
import {Translate} from '../utils/translation'
import {formatDateToLanguageStandard,formatTimeToLanguageStandard} from '../utils/date'

const CardContentNoPadding = styled(CardContent)(`
  
  &:last-child {
    padding-bottom: .3em;
  }
`);

interface FlightProps{
    flights:Flight[]
    airports:Airport[];
    dictionary:Translate
    settings:Settings
}
const FlightPage = (props:FlightProps) =>{

  const flights = props.flights;
  const airports = props.airports;
  const settings = props.settings;
  const dictionary = props.dictionary
  const language = settings.language
    
  useEffect(() => {
    
  },[]);
  
  const getLocationAirport = (acronym:string) => {
 
    if(airports && airports.length>0){

        const airport =  airports.find((airport:Airport)=> airport.acronym===acronym)
        if(airport){
            return airport.location
        }
    }
  }
 
  const renderFlights = (flightsGroup:Flight[])=>{
   
    const flightList = flightsGroup.map((flight:Flight)=>{
        
        const departureTime =  formatTimeToLanguageStandard(flight.partida_horario.padStart(5, '0'),language)
        const suffixDepartureTime = (language.toUpperCase()==='EN') ?  
                                    (<span className='suffix-time'>{departureTime.substring(5,8)}</span>):''
        const arrivalTime   =  formatTimeToLanguageStandard(flight.chegada_horario.padStart(5, '0'),language)
        const suffixArrivalTime = (language.toUpperCase()==='EN') ?  
                                    (<span className='suffix-time'>{arrivalTime.substring(5,8)}</span>):''
        
        const showConexionInformation = (flight.tipo_voo.indexOf('conexao')>=0) ? ( <span style={{marginTop:'.5em',fontSize:7,fontWeight:600,fontFamily:'Open Sans',textAlign:'center',color:"white",
                                                                                                    paddingTop:3,paddingBottom:3,paddingRight:10,paddingLeft:10,borderRadius:15,background:settings.color}}>
                                                                                        {dictionary.getTranslation('conexao')}
                                                                                  </span>) : null

        const showWarningFlight = (flight.aviso && flight.aviso.length>0) ? (<div className="card-warning">
                                                                                <InfoOutlinedIcon sx={{ fontSize:'1em',marginTop:'.1em'}} /> 
                                                                                <span style={{marginLeft:'.5em',fontSize:12,color:'#1B1B1B',fontWeight:300}} >{flight.aviso} </span>
                                                                            </div>): null;                                                                          
        return <div key={flight.localizador} style={{paddingBottom:'1em'}}>
                    <div className="flight_header">
                        <CalendarMonthIcon sx={{ fontSize:'1em',color:'#231F20'}} />
                         <span style={{fontSize:'1em',paddingLeft:'.5em'}}>
                            {formatDateToLanguageStandard(flight.partida_data,language)}
                        </span>
                    </div>
                    
                    <div className="card-container">
                        <Card sx={{ width:'100%' ,padding:0,borderBottomLeftRadius:12,borderBottomRightRadius:12}}>
                            <CardContentNoPadding sx={{paddingBottom:0}}>
                                <div className="flight-transferred-data">
                                    <div className='flight-column'>
                                        <span style={{fontSize:10,fontWeight:300}}>{dictionary.getTranslation('partida')}</span>
                                        <span style={{fontSize:35,color:'#1B1B1B',fontWeight:600,fontFamily:'Open Sans Condensed, sans-serif'}}>
                                            {departureTime.substring(0,5)}  {suffixDepartureTime}
                                        </span>
                                        <span style={{color:'#1B1B1B',fontSize:'20px'}}>{flight.partida_aeroporto}</span>
                                        <span style={{fontSize:12,color:'#1B1B1B',fontWeight:300}}>{formatDateToLanguageStandard(flight.partida_data,language).substring(0,5)}</span>
                                        <span style={{fontSize:9,color:'#1B1B1B',fontWeight:600,textWrap:'wrap',textAlign:'center'}}>{getLocationAirport(flight.partida_aeroporto)?.toUpperCase()}</span>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'.5em',maxWidth:'30%'}}>
                                        <span style={{fontSize:11,textAlign:'center',color:"#1B1B1B",paddingBottom:'.5em'}}>{flight.cia_aerea}</span>
                                        <PlaneSVG id="img-icon"  color={settings.color} className='img_icon'  height={32} width={'40vw'} />
                                        <span style={{fontSize:11,textAlign:'center',color:"#1B1B1B",paddingTop:'.5em'}}>{dictionary.getTranslation('voo')}:{flight.numero_voo}</span>
                                        {showConexionInformation}
                                    </div>
                                    
                                    <div className='flight-column'>
                                        <span style={{fontSize:10,fontWeight:300}}>{dictionary.getTranslation('chegada')}</span>
                                        <span style={{fontSize:35,color:'#1B1B1B',fontWeight:600,fontFamily:'Open Sans Condensed, sans-serif'}} >
                                            {arrivalTime.substring(0,5)}   {suffixArrivalTime}
                                        </span>
                                        
                                        <span style={{color:'#1B1B1B',fontSize:'20px'}}>{flight.chegada_aeroporto}</span>
                                        <span style={{fontSize:12,color:'#1B1B1B',fontWeight:300}}>{formatDateToLanguageStandard(flight.chegada_data,language).substring(0,5)}</span>
                                        <span style={{fontSize:9,color:'#1B1B1B',fontWeight:600,textWrap:'wrap',textAlign:'center'}}>{getLocationAirport(flight.chegada_aeroporto)?.toUpperCase()}</span>
                                    </div>
                                
                                </div>
                                <Divider sx={{marginTop:'.5em',borderStyle:'dashed',color:"#E6EAEE"}}/> 
                                <div className="flight-transferred-data-bottom" style={{padding:0}}>
                                    <div style={{width:"100%",height: '1em'}}>
                                        <span style={{fontSize:11}}>{dictionary.getTranslation('localizador')}: </span><span style={{fontSize:11,color:'#1B1B1B',fontWeight:600}}>{flight.localizador}</span>
                                    </div>
                                    <div>
                                        <span style={{fontSize:11}} >{dictionary.getTranslation('bilhete')}: </span> <span style={{fontSize:11,color:'#1B1B1B',fontWeight:600}}>{flight.bilhete}</span>
                                    </div>
                                </div>
                                
                            </CardContentNoPadding>
    
                        </Card>
                        {showWarningFlight}
                    </div>
                </div>
    })
    return flightList
  }

  
  if(flights.length>0){
    
    let goingFlights:Flight[] = []
    let returningFlights:Flight[] = []
    flights.forEach((flight)=>{
        
        if(flight.tipo_voo.indexOf('ida')>=0){
             goingFlights.push(flight)
        }else if(flight.tipo_voo.indexOf('volta')>=0){
            returningFlights.push(flight)
        }
        
    })
    const backgroundGoingFlights = (goingFlights.length>1) ? {background:'#E1E1E1',paddingBottom:'1em',marginTop:'.5em'}:{}
    const backgroundReturningFlights = (returningFlights.length>1) ? {background:'#E6E6E6',paddingBottom:'1em',marginTop:'1em'}:{}
    
    return(
        <div className="flight_data">
            <div style={{borderRadius:25,paddingLeft:'.5em',paddingRight:'.5em',...backgroundGoingFlights}}>
                {renderFlights(goingFlights)}
            </div>
            <div style= {{borderRadius:25,paddingLeft:'.5em',paddingRight:'.5em',...backgroundReturningFlights}}>
                {renderFlights(returningFlights)}
            </div>
        </div>
    ) 
  }

  return (
        <div className="main">
            <div className="error-message-component">
                <ErrorMessage message={dictionary.getMessagesByCode('nenhum_voo_encontrado')} />
            </div>
        </div>
  );
  

  
}

export default FlightPage;