import React, { useState, useEffect }  from 'react';
import { Tabs, Tab, makeStyles } from "@mui/material";
import FlightIcon from '@mui/icons-material/Flight';
import FlightPage from "./Flight";
import AccommodationPage from './Accommodation';
import Header from "../components/Header"
import { useSearchParams} from  'react-router-dom';
import {getFlightData} from '../services/flight';
import {Flight} from '../models/Flight'
import { Settings } from '../models/Settings';
import { Airport } from '../models/Airport';
import { Translation } from '../models/Translation';
import { Oval } from 'react-loader-spinner'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Accommodation} from '../models/Accommodation'
import { createGlobalStyle } from 'styled-components';
import { ReactComponent as HotelSvg } from '../assets/icons/hotel.svg'
import Divider from '@mui/material/Divider';
import * as moment from "moment"; 
import ErrorMessage from '../components/Error'
import {Translate} from '../utils/translation'
import {formatDateToLanguageStandard} from '../utils/date'
import {TranslateErrorMessage} from '../utils/error'

const tabStyle:any = {
    background: '#D3D3D3',
    border: '1px solid #D3D3D3',
    margin: '.5em',
    minHeight:'0px',
    color: '#737070',
    borderRadius: "2em",
    fontSize:'0.875rem',
    paddingVertical: "2em",
    width:'45vw',
    marginTop: '1em',
    fontFfamily: 'Open Sans, sans-serif'
    
}

const Trip = () =>{
    const [value, setValue] = React.useState(0);
    const [flights, setFlights] = useState<Flight[]>([]);
    const [settings, setSettings] = useState<Settings|null>(null);
    const [dictionary, setDictionary] = useState<Translate>(new Translate());
    const [airports, setAirports] = useState<Airport[]>([]);
    const [accommodations, setAccommodations] = useState<Accommodation[]>([]);
    const [error, setError] = useState<string|null>(null);
    const [loading,setLoading] = useState(true);
    const [timeLoadedPage,setTimeLoadedPage] = useState<string>('')

    const [queryParameters] = useSearchParams()
    const code = queryParameters.get("code");
    const email = queryParameters.get("email")
    const user = queryParameters.get("user")
    const colorMessages =  queryParameters.get("color")

    //Caso queira apresentar apenas uma aba ou invés das duas
    const tabParam = queryParameters.get("tab")

    let tabs = (tabParam) ? [tabParam] : ['voos','hospedagem'] 

    if(tabParam!=='voos' && tabParam!=='hospedagem'){

        tabs = ['voos','hospedagem'] 
    }
   
    
    useEffect(() => {
    
        const handleError = (error?:string)=> {

            if(!error){
                setError('E06');
                return;
            }
            switch (error){

                case 'codigo_planilha_nao_encontrado':
                    setError('E11');
                    break;
                case 'data_voo_invalida':
                    setError('E12')
                    break;
                default:
                    setError('E07')
                    break;

            }

        }
        if(code && email){
            const fetchFlights = async()=>{
                setLoading(true)
                const data = await getFlightData(code,email)
                if(data && data['settings'] && data['flights'] && data['airports'] && data['accommodations']){
                    const settingsData:Settings = {
                        client:data['settings']['cliente'],
                        destiny:data['settings']['destino'],
                        color:data['settings']['cor'],
                        language: (data['settings']['idioma'])? data['settings']['idioma']:'pt'
                    }
                   
                   
                    const translationsData:Translation[] = data['translations'].map((translation:any) =>  {
                        return {
                            code:translation.code,
                            pt:translation.pt,
                            en:translation.en,
                            es:translation.es
                        }
                    })
                    const dictionaryData = new Translate(translationsData,settingsData.language)
                    const airportsData:Airport[] = data['airports'].map((airport:any) =>  {
                        return {
                            acronym:airport.sigla,
                            location:dictionaryData.getLocationAirportByLanguage(airport)
                        }
                    })
                    const accommodationsData = data['accommodations'].map((accommodation:any)=>{
                        return {
                            name:accommodation["hotel_nome"],
                            email:accommodation["email"],
                            guest:accommodation["nome"],
                            address:accommodation["hotel_endereço"],
                            checkin_date:accommodation["checkin_data"],
                            checkin_time:accommodation["checkin_hora"],
                            checkout_date:accommodation["checkout_data"],
                            checkout_time:accommodation["checkout_hora"]
                        }
                    })
                    setSettings(settingsData);
                    setDictionary(dictionaryData)
                    setFlights(data['flights']);
                    setAirports(airportsData);
                    setAccommodations(accommodationsData)
                }else{
                    const errorResponse = (data && data['error'])?data['error']: null
                    handleError(errorResponse)
                }   
                setLoading(false)
                

            }
            fetchFlights()
            const today = moment().format('DD/MM/YYYY HH:mm:ss')
            setTimeLoadedPage(today)
            
        }else{
            setLoading(false)
            setError('E05');
        } 
        
    },[]);

    const loadTabSelectedStyle = (settings:Settings)=>{

        if(settings.color){

            const TabSelectedStyle = createGlobalStyle`
                .tab-selected {
                    background-color: ${settings.color}  !important;
                    border-radius: 2em !important;
                    min-height: 0px !important;;
                    color: #FFFFFF !important;
                    font-weight: normal !important;
                }`
            return <TabSelectedStyle/>
        }
       
    }

    if(loading){

        return  (
            <div className="loading-data">
                <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#737070"
                        secondaryColor="#737070"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
            </div>
        );
    }
    const language = (settings)?settings['language']:undefined
    if(error){
        const codeError = error
        
        const errorMessage = new TranslateErrorMessage(language)
        return  (
                <div className="main">
                <div className="error-message-component">
                    <ErrorMessage message={errorMessage.getMessagesByCode(codeError)} code={codeError} colorMessage={(settings)? settings.color:colorMessages??'' }/>
                </div>
            </div>
            )
    }
    if(!settings){

         const codeError = 'E07'
        
        const errorMessage = new TranslateErrorMessage(language)
        return  (
                <div className="main">
                <div className="error-message-component">
                    <ErrorMessage message={errorMessage.getMessagesByCode(codeError)} code={codeError}/>
                </div>
            </div>
            )
    }
    
    let labelFlights:string="";
    let labelAccomodations:string="";
    let lastUpdate:string="";
   
    labelFlights = dictionary.getTranslation('voos')
    labelAccomodations = dictionary.getTranslation('hospedagem')
    lastUpdate = dictionary.getTranslation('atualizado_em')
    
    
    const getTabsComponent = (tabs:string[])=>{

        return tabs.map((tab:string)=>{
            
            switch (tab){

                case 'voos':
                    return <Tab icon={<FlightIcon />} label={labelFlights} iconPosition="start" classes={{ selected: 'tab-selected' }} sx={tabStyle} />
                case 'hospedagem':
                    return <Tab icon={<HotelSvg />}  label={labelAccomodations} iconPosition="start" classes={{ selected: 'tab-selected' }} sx={tabStyle} />
                default:
                    return null;
            }   
        })

    }
    const getTabsContent = (tabs:string[]) =>{
        return tabs.map((tab:string)=>{
            switch (tab){

                case 'voos':
                    return <FlightPage flights={flights} airports={airports} settings={settings} dictionary={dictionary}/>
                case 'hospedagem':
                    return <AccommodationPage accommodations={accommodations} settings={settings} dictionary={dictionary}/>
                default:
                    return null;
            }
        })
    }
    const tabsContent = getTabsContent(tabs)
    let screen = (value===0) ? tabsContent[0]:<AccommodationPage accommodations={accommodations} settings={settings}  dictionary={dictionary}/>
    if(tabsContent.length>1){
        screen = (value===0) ? tabsContent[0]:tabsContent[1]
    }else{
        screen =  tabsContent[0]
    }
    
    const users = (flights.length>0) ? flights.map(flight=>flight.nome): [user??'']
   
    let footerMessage = lastUpdate + ' ' + formatDateToLanguageStandard(timeLoadedPage,language,true)
    return(
       <div className='show-data'>
      
       {loadTabSelectedStyle(settings)}
       <Header settings={settings} user={users[0]} ></Header>
      
       
        <Tabs
            value={value}
            sx={{width:'auto',justifyContent: 'center'}}
            onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            aria-label="icon position tabs example"
             TabIndicatorProps={{
                    style: { background: "#EDEDED" }
                }}
            >
            {getTabsComponent(tabs)}
        </Tabs>
        {screen}
       
        <div style={{width:'80%'}}>
             <Divider sx={{color:'#979797'}}/>
           <p style={{color:'#979797',fontSize:12,textAlign:'center'}}>{footerMessage}</p>
        </div>
        </div>
    )
}

export default Trip;