import React from "react";
import { Route, BrowserRouter,Routes  } from "react-router-dom";
import packageJson from '../package.json';

import Trip from './pages/Trip'

const RoutesApp = () => {
   
   let path = '/'
   let  url = window.location.href 
   if (url.indexOf('localhost:3006')>=0){
        path = '/app/viagens'
   }    
   else if(packageJson.pathpage){
     path = packageJson.pathpage
   }
   return(
       <BrowserRouter basename={path}>
          <Routes>
            <Route element={<Trip/>}  path="/" />
          </Routes>
       </BrowserRouter>
   )
}


export default RoutesApp;